//@use '@angular/material' as mat;
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
//@import '~jsoneditor/dist/jsoneditor.min.css';
//@import "../node_modules/prismjs/themes/prism-coy.css";
//@import '~jsoneditor/dist/jsoneditor.min.css';


@import "mainMixins";
@import "./variables";

html {
  overflow: hidden;
}


body {
  font-family: "Source Sans Pro", Arial, sans-serif;

  .list-active {
    background: var(--primary-color) !important;
    color: var(--primary-color-text) !important;
  }

}

.p-chip .p-chip-text {
  font-size: 12px
}

.p-confirm-popup {
  z-index: 1200;
}

.p-dialog {
  z-index: 2500;
}

#layout-main-content{
  background: $dim;
}

.card_bg{
  background: $card-background-color;
}

.card-border {
  border: 2px solid $card-border-color !important;
}

.p-confirm-popup {
  z-index: 12102 !important;
}

:host ::ng-deep .profile-avatar {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.p-calendar {
  .p-datepicker {
    top: 3rem !important;
    left: -147px !important;
  }
}
.delievry-analytics-cascadeSelect .p-cascadeselect-item-active>.p-cascadeselect-sublist{
  top: auto !important;
  left: auto !important;
}

.scroll-side-menu {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #00000008;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.dropdownMenu-image {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

////--california scss
//.layout-wrapper .layout-sidebar {
//  z-index: 1001;
//}
//
//.layout-wrapper .layout-megamenu {
//  z-index: 2000;
//  max-width: 750px;
//}
//
.layout-wrapper .layout-main .layout-main-content {
 overflow-y: auto;
}
//
//#layout-main-content.split-view-active {
//  margin-right: 750px;
//}
//
//.layout-wrapper .layout-main .route-bar .route-bar-breadcrumb {
//  padding: 9px 12px;
//}

//.layout-config {
//  z-index: 2001;
//}

//.layout-wrapper .layout-megamenu.layout-megamenu-dark ul li.active {
//  border-left: 4px solid #7C91C4;
//  color: #7C91C4;
//}
//
//.layout-wrapper .layout-megamenu .layout-megamenu-submenu ul li span {
//  font-size: 1.3rem !important;
//}
//
//.layout-wrapper .layout-sidebar .lower-menu {
//  padding-bottom: 0px;
//}
//
//#sidebar-usermenu.usermenu {
//  padding-bottom: 0px;
//}
//
.layout-wrapper .layout-sidebar .layout-menu {
 padding-bottom: 50px;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu :hover {
  text-decoration: none !important ;
}
//
//.layout-wrapper .layout-main .route-bar .route-bar-breadcrumb i {
//  vertical-align: baseline;
//}

.layout-wrapper .layout-sidebar .layout-menu {
 padding-bottom: 50px;
}
.layout-wrapper .layout-sidebar.layout-sidebar-dark .layout-menu :hover {
  text-decoration: none !important ;
}

.sidebar-scroll-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.things-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    margin-left: 5px;
  }
}

.things-item .p-badge {
  font-weight: initial;
}

.custom-modal-title {
  margin-bottom: 0 !important;
  line-height: 1.38 !important;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
//--------------newly added CSS------------
.p-button.full-size-btn, .p-button-success {
  display: block !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.row-clickable-button {
  color: var(--primary-color) !important;
}

.row-clickable-button:hover {
  cursor: pointer;
}

.ng-date-picker-container .ng-date-picker-label{
  color: $card-label-color;
.calender-icon-grid {
  left: -20px !important;
 }
}

//-------------timeline-----------------
.timeline.mainTtl {
  position: sticky;
  top: -12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 100;
  background: $dim
}

.timeline.mainTtl .title {
  width: calc(100% - 60px);
}

h5.p-card-title {
  font-size: 15px;
  font-weight: 400;
  color : var(--primary-color);
}
.p-card-subtitle {
  color : $medGry;
  font-size : 14px;
}
.selected h5.p-card-title{
  font-size:17px;
}

.p-component {
  font-size: 14px !important;
}


.trackable-cars-list, .geofence-confirm-box {
  background-color: var(--surface-a) !important;
  color: var(--text-color) !important;
}

.md-drppicker {
  z-index: 1003 !important;
  background: var(--surface-a) !important;
  box-shadow: 0 0px 11px 3px rgba(0, 0, 0, 0.6) !important;
  left: auto !important;

  .ranges {
    ul li {
      button {
        // background: var(--primary-color-text) !important;
        color: var(--primary-color) !important;
      }
    }
  }

  .calendar-table {
    background: var(--surface-a) !important;
    border: var(--surface-a) !important;

    th {
      // color: var(--surface-a) !important;
      //&.prev {
      //  &:hover {
      //    background: $side-bar-primary !important;
      //  }
      //}
    }

    td {
      &.active {
        background: var(--primary-color) !important;
        color: var(--primary-color-text) !important;

        &.start-date .available, &.end-date .available {
          background: #ffffff !important;
          color: $side-bar-primary-active !important;

          &:hover {
            background: #ffffff !important;
            color: $side-bar-primary-active !important;
          }
        }

        &.off .start-date .available, &.off .end-date .available {
          background: none !important;
          color: $side-bar-text-color !important;
        }

        &:hover {
          background: var(--primary-color) !important;
          color: var(--text-color) !important;
        }
      }

      &:hover {
        background: var(--primary-color) !important;
        color: var(--text-color) !important;
      }

      &.off {
        background: none !important;
        color: #999 !important;
      }
    }
  }

  .btn {
    background: var(--primary-color) !important;
    color: var(--primary-color-text) !important;
  }

  .ranges {
    ul li {
      button {
        color: var(--primary-color) !important;

        &.active {
          background: var(--primary-color) !important;
          color: var(--primary-color-text) !important;

          &:hover {
            background: var(--primary-color) !important;
            color: var(--primary-color-text) !important;
          }
        }

        &:hover {
          background: var(--surface-b) !important;
        }
      }
    }
  }

  .calender {
    &.left {
      .calendar-table {
        background: var(--surface-a) !important;
      }

      .md-drppicker .calendar-table {
        // background: $body-background-color !important;
        background: var(--surface-a) !important;

      }
    }
  }
}


//-----------analytics-button style changes------
.p-button.p-button-icon-only {
  padding: 0.2rem 0 !important;
}

.p-dialog .p-dialog-header {
  padding: 0 1.5rem !important;
}

.panel-toggle-button {
  background-color: #FFFFFF;
  position: absolute;
  width: 30px;
  height: 60px;
  border: 1px solid;
  cursor: pointer;

  .arrow-icon {
    margin: 90% 50%;
    transform: translate(-50%, -50%);
  }
}

.playback-value-container {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  border-radius: 100px;
}

.as-vertical .as-split-gutter {
  height: 16px !important;
}

.as-split-gutter-icon{
  background: var(--primary-color) !important;
}

.noMargin {
  margin: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.MT5 {
  margin-top: 5px;
}

.MT20 {
  margin-top: 20px !important;
}

.MT30 {
  margin-top: 30px;
}

.ML20 {
  margin-left: 20px;
}

.ML10 {
  margin-left: 10px;
}

.MR10 {
  margin-right: 10px !important;
}

.MR30 {
  margin-right: 30px;
}

.MB10 {
  margin-bottom: 10px;
}

.MB5 {
  margin-bottom: 5px;
}

.MR20 {
  margin-right: 20px;
}

.MT10 {
  margin-top: 10px !important;
}

.PT10 {
  padding-top: 10px;
}

.P10 {
  padding: 10px;
}

.MT5B5 {
  margin: 5px 0 5px 0;
}

.W100FL {
  width: 100%;
  float: left;
}

.FR {
  float: right;
}

.FW500 {
  font-weight: 500;
}

.PR {
  position: relative;
}

.TAC {
  text-align: center;
}

.PR {
  position: relative;
}

.TTC {
  text-transform: capitalize;
}

.full-width {
  width: 100%;
}

.W-200 {
  width: 200px;
}

.cursorPointer {
  cursor: pointer;
}

.video-feed-container {
  video::-webkit-media-controls-play-button, video::-webkit-media-controls-timeline,
  video::-webkit-media-controls-current-time-display, video::-webkit-media-controls-mute-button,
  video::-webkit-media-controls-time-remaining-display, video::-webkit-media-controls-volume-slider,
  video::-webkit-media-controls-toggle-closed-captions-button, video::-webkit-media-controls-picture-in-picture-button {
    display: none;
  }
}

.modal, .custom-modal, .custom-modal-sm {
  // background-color: rgba(0, 0, 0, 0.6) !important;
  border-left: none !important;
  // z-index: 2500;
}

.white-text {
  color: #fff;
}

.pass {
  background-color: #4b7113;
  color: #fff
}

.check {
  background-color: #ffc107;
  color: #fff
}

.fail {
  background-color: #dc3545;
  color: #fff
}

.allow-cr {
  white-space: pre-line;
}

.dotsOnOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anchor {
  text-decoration: underline;
  font-weight: 400;
}

.cdk-overlay-container {
  z-index: 1500 !important;
}

//.box-shadow {
//  -moz-box-shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
//  -webkit-box-shadow:  0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
//  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
//  border: 0;
//}
.TAC {
  text-align: center;
}

//button:focus {
//  outline: none;
//}

.messagePopup {
  @include boxShadow(0 0 2px rgba(0, 0, 0, 0.3));
  min-width: 300px;
  z-index: 5000;
  display: none;
  padding: 20px;
  top: 150px;
  left: 50%;
  margin-left: -150px;
  background: #425079;
  color: #fff;
  position: fixed;
  box-sizing: border-box;
  // width: 300px;
  text-align: center;
  font-size: 15px;
  border-radius: 6px;
}

.asset-not-found, .asset-not-found-centerAligned {
  font-style: italic;
  text-align: center;
  line-height: 1.45;
  position: relative;
  color: #888686;

  span {
    font-size: 33px;
  }
}

.calender .dropdown-menu.show {
  top: 46px !important;
}

.dropdown-item:hover {
  cursor: pointer;
}

.arrow-animation {
  -webkit-animation: rotation 1s 1 linear;
  -moz-animation: rotation 1s 1 linear;
  -o-animation: rotation 1s 1 linear;
  animation: rotation 1s 1 linear;
}

//.sidebar-modal-header{
//  height: 55px !important;
//  padding: 8px 15px !important;
//}
.small-modal-header {
  height: 55px !important;
}

.select-search-filter-dropDown {
  list-style: none;
  padding-left: 0px;
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0px;
  overflow: hidden auto;
  max-height: 197px;
  box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, .5) !important;

  li {
    cursor: pointer;
    padding: 0px !important;
    border-bottom: 1px solid #737fc8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      padding: 10px 0px !important;
      font-size: 14px;
      text-align: center;
      cursor: default;
      border: 1px solid;
      position: sticky;
      top: 0px;
    }

    &:last-child {
      border: none !important;
    }

    .personName {
      font-size: 13px;
      margin: 2% 3%;
      line-height: 3em;
      height: 3em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 2% 3%;
      min-width: 40px;
    }
  }
}

.timePicker-label {
  font-size: 12px !important;
}


.input-outer-grid {
  .guide {
    position: absolute;
    top: 7px;
    right: 0;
    color: red;
  }

  .filterLoader {
    position: absolute;
    top: 7px;
    right: 0;
    -webkit-animation-name: rotateSpinner;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }
}

.map-header-container {
  &.page-filter {
    padding: 5px 12px;

    .flex-container {
      align-items: center;

      .input-outer-grid {
        max-width: 100%;

        &.date-picker-grid {
          margin-bottom: 1em;
        }
      }

      .filter-buttons-container {
        margin-left: auto;
        // padding: 10px 5px;
        button {
          margin: 10px 10px 1em;
        }
      }
    }
  }
}

.map-content-wrapper{
  border: 1px solid #ced4da !important;
}

@-webkit-keyframes rotateSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@include keyframes(rotation) {
  from {
    @include rotate(0);
  }
  to {
    @include rotate(359);
  }
}

.inner-container-header-btn {
  font-size: 25px !important;
  cursor: pointer;
  line-height: 30px;
}

.btn {
  padding: 10px 15px;
  margin: 5px;
  border-radius: 8px;
}

.split-view {
  margin: 0px !important;
  width: calc(100% - 860px) !important;
}

//----------json editor-css-changes ----------------------//
.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none;
}

.ace-jsoneditor .ace_invalid {
  background-color: transparent !important;
}

//-------- leaflet custom css changes  --------------- //
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-touch .leaflet-bar a:first-child, .leaflet-touch .leaflet-bar a:last-child {
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 35px;
  line-height: 30px;
}

//-------- custom scrollbar css changes  --------------- //
::-webkit-scrollbar-track {
  @include boxShadow(inset 0 0 6px rgba(0, 0, 0, .4));
  background-color: #F5F5F5;
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  // background-color: #7386D5;
}

// ---------------- ng-date-picker css chnages -------------------//
.ng-date-picker-input {
  width: 100%;
  float: left;
  cursor: pointer;
  //pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-width: 0;
  color: rgba(0, 0, 0, 0.87);
  //font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// -------------------- custom modal css  -------------------- //
.custom-modal-dialog {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1046;
  top: 0;
  left: 0;
  display: none;
}

.custom-modal {
  // background: #FFFFFF !important;
  display: none;
  height: 100%;
  // height: 100vh; //fallback value
  // position: absolute;
  // right: 0;
  // top: 60px;
  // top: 0;
  width: 750px;
  z-index: 1044 !important;
  // transition: 2s ease-in-out all;

  .custom-modal-content {
    height: 100%;
    padding-bottom: 5%;

    .custom-modal-header {
      // height: 70px;
      padding: 8.5px !important;
      text-transform: capitalize;
      /* display: flex;
      align-items: center;
      justify-content: space-between; */
    }

    .custom-modal-body {
      height: 100%;
      // height: calc(100% - 1.75rem) !important;
      overflow: hidden auto;
      position: relative;
      padding: 1rem;
    }
  }
}

.custom-modal-sm {
  z-index: 1044 !important;
}

.prime-table-container {
  flex: 1 1 auto;
  overflow: auto;
}

.custom-modal-view {
  //transform: translateX(100%);
  // animation: sliding 0.26s ease-in-out;
}

@keyframes sliding {
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0)
  }
}
// ----------------extend button when hover---------------------//
.extend-button-onHover {
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  overflow:hidden;
  width: auto;
  max-width: 32px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s ease-in;
  .pi{
    margin-left: 9px;
    color: var(--primary-color-text);
  }
  &__button-to-extend{
    white-space: nowrap;
    padding-right: 15px;
    color: var(--primary-color-text);
    .p-button:enabled:hover {
      background-color: var(--primary-color);
    }
     .p-button{
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.extend-button-onHover:hover {
  max-width:300px;
}

//--------------------bootstarp modal---------------------------//

.modal.right-slider-modal {
  .modal-header {
    height: 55px;
    padding: 8px 15px;
  }

  .modal-content .close {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--primary-color);
      color: white;
    }
  }
}

//--------------------placholder loader --------------------------//
.curtain {
  min-height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: hidden;
  z-index: 20;
  transition: all 0.3s;

  &:hover {
    transform: scale(2);
  }
}

.shine {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  //background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background: linear-gradient(-90deg, #efefef 0%, #dddddd 50%, #efefef 100%);
  background-size: 400% 400%;
  -webkit-animation: shine 1.3s infinite;
  animation: shine 1.3s infinite;
  opacity: 0.8;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

@keyframes shine {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

//-------- media  --------------- //
@media (min-width: 320px) and (max-width: 480px) {
  .asset-not-found {
    margin-top: 10px;
    line-height: 30px;
    font-size: 24px;

    span {
      font-size: 28px;
    }
  }
  .p-calendar {
    .p-datepicker {
      left: auto !important;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .modal .modal-full-height.modal-lg {
    max-width: 90%;
    width: 90%;
  }
  //.glide__arrow{
  //  padding: 15px 8px;
  //}
  //.glide__arrow--left{
  //  left: -1em;
  //}
  //.glide__arrow--right{
  //  right: -1em;
  //}
}

@media (min-width: 768px) and (max-width: 900px) {
  .marker-app > #feedback-button {
    margin-right: -123px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  .asset-not-found {
    margin-top: 10px;
    line-height: 30px;
    font-size: 24px;

    span {
      font-size: 28px;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .modal .modal-full-height.modal-lg {
    max-width: 80%;
    width: 80%;
  }
  //.glide__arrow{
  //  padding: 15px 8px;
  //}
  //.glide__arrow--left{
  //  left: -1em;
  //}
  //.glide__arrow--right{
  //  right: -1em;
  //}
}

@media (min-width: 768px) and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .modal .modal-full-height.modal-lg {
    max-width: 80%;
    width: 80%;
  }

  .showFullScreen {
    transform: translate(50px, -47%);
    height: 100vh !important;
  }
}

.modal-backdrop.fade.show {
  //background:none!important;
  display: none !important;
}

//.cdk-live-announcer-element.cdk-visually-hidden{
//  display: none;
//}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .modal .modal-full-height.modal-lg {
    max-width: 60%;
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .modal .modal-full-height.modal-lg {
    max-width: 800px !important;
    width: 800px !important;
  }
}

@media (min-width: 1280px) {
  .modal .modal-full-height.modal-lg {
    max-width: 800px !important;
    width: 800px !important;
  }
}


#main-container.split-view-active {
  margin-left: 0px;
  width: calc(100% - 750px);
  overflow: hidden auto !important;

  &.withSmallModal {
    width: calc(100% - 400px) !important;
  }

  .content-wrapper {
    margin-left: 90px;
    overflow: hidden;
    flex: 0 0 calc(100% - 90px);
    max-width: calc(100% - 90px);

    .asset-cards-wrapper {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  #sidebar-right, #sidebar-right-to-add, #sidebar-faceMatch, #user-edit-modal {
    top: 56px;
    height: calc(100vh - 57px) !important;
  }

  .content-wrapper.assetMenuHidden {
    margin: 0 !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}


.tab-specific-button {
  z-index: 100;
  // margin-right: 10px;

  .plus-btn {
    margin: 0px 6px !important;
    font-size: 12px !important;
    min-width: 0px !important;
    padding: 0px 10px !important;
    line-height: 30px !important;
  }
}

.linked-assets-row {
  .linked-asset-deck {
    padding: 10px;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;
      box-shadow: none !important;
      border-radius: 0px;
      border: 1px solid;
      padding: 1%;
      text-align: left !important;

      .linked-btn-label {
        display: inline-block;
        font-size: 12px;
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon-container {
        display: inline-block;
        padding: 8px 8px;
        border-radius: 50px;
        margin: 1% 4%;
      }

      .badge {
        position: absolute;
        top: 4px;
        right: 7px;
        font-size: 12px;
        border-radius: 50%;
      }
    }
  }
}

.header {
  height: auto;
  min-height: 45px;
  margin-top: -9px !important;
  margin-bottom: 0px !important;

  &.no-buttons {
    height: 60px;
  }

  .header-inner-grid {
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .bread-crumb {
      max-width: max-content;
      font-size: 19px;
      text-transform: capitalize;
      margin-right: 10px !important;

      .bread-crumb-inner-grid {
        align-items: center;
      }

      .bread-crumb-seperator {
        margin: 0px 8px;
      }
    }

    .header-buttons-container {
      max-width: max-content;
      margin-left: auto !important;

      .header-buttons-container-inner-grid {
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}


.tab {
  button, a {
    border-color: transparent !important;
  }
}

.card-text:last-child {
  margin-bottom: 0px !important;
  //padding-bottom: 0px !important;
}

#content.active {
  .assets-hover-menu {
    left: 8.5%;
  }
}

.searchBox-sticky {
  position: sticky;
  top: 0;
  left: 0;
  background: inherit;
  z-index: 999;
}

.grid-item {
  &.dash-board-grid-item {
    margin-bottom: 40px;
    margin-bottom: 20px;
    padding: 15px 1%;
  }

  &.fixed-height {
    min-height: 432px;
  }
}

.map-filter-buttons-grid {
  .chart-filter-dropdown .btn#dropdownMenuButton {
    top: -4px !important;
    right: -4px !important;
  }
}

.chart-filter-dropdown {
  .btn#dropdownMenuButton {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .dropdown-menu {
    .list-title {
      letter-spacing: 1px;
      font-size: 12px;
      opacity: .6;
      margin: 5px 10px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .dropdown-item {
      font-weight: bold;
      text-transform: capitalize;

      &:active {
        color: inherit;
      }

      img {
        &.filter-menu-item-icon {
          width: 14px;
        }
      }

      &.no-icon {
        text-align: center;
      }
    }
  }
}

.chart-filter-dropdown {
  .dropdown-menu {
    .dropdown-item {
      &.active {
        color: var(--primary-color-text) !important;
        background-color: var(--primary-color) !important;
      }
    }
  }
}

//--------------primeNg css chages------------------------
.dark-theme .p-dialog-mask.p-component-overlay,
.light-theme .p-dialog-mask.p-component-overlay {
  background: #000000ad;
}

.p-button.p-button-icon-only {
  border: 0;
}

p-button.outsideButton {
  position: absolute;
  width: 35px !important;
  padding: 0 !important;
  border: 0 !important;
  top: 25px !important;
  right: 5.5rem !important;
  z-index: 100 !important;
}

p-button.outsideButton.corner {
  right: 20px !important;
}

.outsideButton .pi-external-link:before {
  content: "\e93b" !important;
}

.my-1 .p-dialog-header-close-icon.pi-times:before {
  content: "\e93a" !important;
}

.p-dialog-content {
  background: #ffffff !important;
  overflow-y: inherit !important;
}

.p-dialog {
  background: #ffffff !important;
}

.p-dialog {
  padding: 15px;
  box-sizing: border-box;
}

.p-dialog .p-dialog-header-icon {
  margin-bottom: 10px !important;
}


.p-timeline-event-connector {
  flex-grow: 1;
  width: 2px !important;
  // background:$card-background-secondary-color;
  background: rgba(0, 0, 0, 0.09);
}

.p-column-filter-overlay {
  max-width: 330px;
  z-index: 1000 !important;
}

.p-datatable.fixedHeight {
  height: 80vh;
  overflow: auto;
}

.order-count-stats-container {
  // flex-wrap: wrap;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
  color: var(--primary-color-text);
  background-color: var(--primary-color);
}

.p-column-filter-menu-button{
  margin-left: 5px;
}

.asset-icon-color {
  filter: brightness(0) invert(1);
}

.light-asset-icon-color {
  filter: brightness(0) grayscale(100%);
}
.zmdi {
  color: var(--primary-color) !important;
}

// --------------------------------------------------- media Queries (split-screen) ----------------------------------------------------------------
@media (max-width: 575px) {
  #content {
    .assets-hover-menu {
      left: 12%;
    }

    &.active {
      .assets-hover-menu {
        left: 23.5%;
      }
    }
  }
  .custom-modal {
    .linked-assets-row {
      .linked-asset-deck {
        padding: 5px;

        a .badge {
          font-size: 10px;
        }
      }
    }
  }
  #main-container.split-view-active {
    .auto-registered-card-deck, .faceMatchCard-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  .showFullScreen {
    transform: translate(33px, 47px);
    height: calc(100vh - 0px) !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #content {
    .assets-hover-menu {
      left: 12%;
    }

    &.active {
      .assets-hover-menu {
        left: 23%;
      }
    }
  }
  #main-container.split-view-active {
    .auto-registered-card-deck, .faceMatchCard-deck {
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }
  }

  .showFullScreen {
    transform: translate(33px, 47px);
    height: calc(100vh - 0px) !important;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  #content {
    .assets-hover-menu {
      left: 8%;
    }

    &.active {
      .assets-hover-menu {
        left: 16%;
      }
    }
  }
  #main-container.split-view-active {
    .auto-registered-card-deck, .faceMatchCard-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }

    .asset-card-deck {
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }
  }

  .showFullScreen {
    transform: translate(33px, 47px);
    height: calc(100vh - 0px) !important;
  }
}

@media (max-width: 991px) {
  .custom-modal, .custom-modal-sm {
    z-index: 1047 !important;
    // margin: 0 50%;
    // transform: translate(50%, 0px);
    border: none !important;
    width: 100%;
  }
  #main-container.split-view-active {
    width: 100% !important;

    .custom-modal-dialog {
      display: block;
    }

    &.withSmallModal {
      width: 100% !important;

      .custom-modal-dialog {
        display: block;
      }

      .custom-modal-sm {
        min-width: 300px;
        max-width: 300px;

        // &.custom-modal-view {
        //   transform: translateX(50%);
        // }
      }
    }

    .custom-modal {
      width: 90% !important;

      .tabcontent {
        height: 90% !important;
      }
    }

    .faceMatchCards-Container {
      .faceMatchCard-deck {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }

  .showFullScreen {
    transform: translate(33px, 47px);
    height: calc(100vh - 0px) !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #main-container.split-view-active {
    width: calc(100% - 474px) !important;

    .custom-modal {
      width: 470px !important;
    }

    .assets-menu {
      min-width: 7%;
    }

    .content-wrapper {
      margin-left: 20%;
      -webkit-box-flex: 0;
      flex: 0 0 calc(100% - 20%);
      max-width: calc(100% - 20%);
    }

    .bread-crumb, .add-asset-btn-container, .header-button-container {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .asset-card-deck, .thingType-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .faceMatchCards-Container {
      padding: 0% 3%;

      .faceMatchCard-deck {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  #content {
    .assets-hover-menu {
      left: 8%;
    }

    &.active {
      .assets-hover-menu {
        left: 15.5%;
      }
    }
  }
  #main-container.split-view-active {
    .header {
      margin: 5px 0px 8px !important;

      .bread-crumb, .header-buttons-container {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #content {
    .assets-hover-menu {
      left: 24%;
    }

    &.active {
      .assets-hover-menu {
        left: 14.5%;
      }
    }
  }
  #main-container.split-view-active {
    .header {
      margin: 5px 0px 8px !important;

      .bread-crumb, .header-buttons-container {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  #main-container {
    .asset-card-deck, .details-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }
  }
  #content {
    .assets-hover-menu {
      left: 24%;
    }

    &.active {
      .assets-hover-menu {
        left: 14.5%;
      }
    }
  }

  #main-container.split-view-active {
    width: calc(100% - 650px) !important;

    .header {
      margin: 5px 0px 8px !important;

      .bread-crumb, .header-buttons-container {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }

    &.withSmallModal {
      .auto-registered-card-deck {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }

    .custom-modal {
      width: 650px !important;
    }

    .content-wrapper {
      margin-left: 23% !important;
      -webkit-box-flex: 0;
      flex: 0 0 calc(100% - 23%);
      max-width: calc(100% - 23%) !important;
    }

    .bread-crumb, .add-asset-btn-container, .header-button-container {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .asset-card-deck, .thingType-card-deck, .details-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .faceMatchCards-Container {
      padding: 0% 3%;

      .faceMatchCard-deck {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 1367px) and (max-width: 1699px) {
  #main-container {
    .asset-card-deck, .details-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }
  }

  #main-container.split-view-active {
    &.withSmallModal {
      .auto-registered-card-deck {
        max-width: 33%;
        flex: 0 0 33%;
      }
    }

    .assets-menu {
      min-width: 5%;
      max-width: 5%
    }

    .content-wrapper {
      margin-left: 14%;
      flex: 0 0 calc(100% - 14%);
      max-width: calc(100% - 14%);
    }

    .asset-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .faceMatchCard-deck, .thingType-card-deck, .details-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }

    .services-content-wrapper {
      padding: 0px;

      .asset-card-deck {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  #content {
    .assets-hover-menu {
      left: 18.5%;
    }

    &.active {
      .assets-hover-menu {
        left: 13.5%;
      }
    }
  }
  #main-container.split-view-active {
    .header {
      margin: 5px 0px !important;

      .bread-crumb, .header-buttons-container {
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
}

@media (min-width: 1440px) and (max-width: 1599px) {
  #main-container.split-view-active {
    .assets-menu {
      min-width: 5%;
    }

    .content-wrapper {
      margin-left: 14%;
      flex: 0 0 calc(100% - 14%);
      max-width: calc(100% - 14%);
    }

    .asset-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  #content {
    .assets-hover-menu {
      left: 15.5%;
    }

    &.active {
      .assets-hover-menu {
        left: 8.5%;
      }
    }
  }
  #main-container.split-view-active {
    .assets-menu {
      min-width: 5%;
    }

    .content-wrapper {
      margin-left: 11%;
      flex: 0 0 calc(100% - 11%);
      max-width: calc(100% - 11%);
    }

    .asset-card-deck {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}

@media (min-width: 1700px) and (max-width: 1799px) {
  #main-container {
    .asset-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }
  }

  #main-container.split-view-active {
    .content-wrapper {
      margin-left: 11% !important;
      flex: 0 0 calc(100% - 11%);
      max-width: calc(100% - 11%);
    }

    .asset-card-deck {
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }

    .faceMatchCard-deck, .thingType-card-deck {
      max-width: 33.333% !important;
      flex: 0 0 33.333% !important;
    }
  }
}

@media (min-width: 1800px) and (max-width: 2399px) {
  #main-container.split-view-active {
    &.withSmallModal {
      .auto-registered-card-deck {
        max-width: 24%;
        flex: 0 0 24%;
      }
    }

    .content-wrapper {
      flex: 0 0 calc(100% - 8.5%);
      max-width: calc(100% - 8.5%);
      margin-left: 8.5% !important;
      padding: 0px 15px !important;
    }

    .faceMatchCard-deck {
      max-width: 33.333% !important;
      flex: 0 0 33.333% !important;
    }
  }
}

@media (min-width: 2400px) and (max-width: 2999px) {
  #main-container.split-view-active {
    &.withSmallModal {
      .auto-registered-card-deck {
        max-width: 16%;
        flex: 0 0 16%;
      }
    }

    .content-wrapper {
      flex: 0 0 calc(100% - 6%);
      max-width: calc(100% - 6%);
      margin-left: 6% !important;
      padding: 0px 15px !important;
    }

    .asset-card-deck, .details-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }

    .faceMatchCard-deck {
      max-width: 25% !important;
      flex: 0 0 25% !important;
    }
  }
}

@media (min-width: 3000px) and (max-width: 3499px) {
  #main-container.split-view-active {
    width: calc(100% - 1200px);

    .custom-modal {
      width: 1200px !important;
    }

    .assets-menu {
      min-width: 3%;
      max-width: 3%;
    }

    .content-wrapper {
      flex: 0 0 calc(100% - 6%);
      max-width: calc(100% - 6%);
      margin-left: 6% !important;
      padding: 0px;
    }

    .asset-card-deck, .details-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }

    .faceMatchCard-deck {
      max-width: 20% !important;
      flex: 0 0 20% !important;
    }
  }
}

@media (min-width: 3500px) {
  .faceMatchCard-deck {
    max-width: 12% !important;
    flex: 0 0 12% !important;
  }
  #main-container.split-view-active {
    width: calc(100% - 1250px);

    .custom-modal {
      width: 1250px !important;
    }

    .assets-menu {
      min-width: 3%;
    }

    .content-wrapper {
      flex: 0 0 calc(100% - 5.5%);
      max-width: calc(100% - 5.5%);
      margin-left: 5.5% !important;
      padding: 0px;
    }

    .asset-card-deck {
      max-width: 25% !important;
      flex: 0 0 25% !important;
    }

    .details-card-deck {
      max-width: 33% !important;
      flex: 0 0 33% !important;
    }

    .faceMatchCards-Container {
      .faceMatchCard-deck {
        max-width: 20% !important;
        flex: 0 0 20% !important;
      }
    }
  }
}

//-----------------------------------------------
@media (min-width: 1200px) and (max-width: 1366px) {
  #content.active {
    #main-container.split-view-active {
      .faceMatchCards-Container {
        padding: 0% 2% !important;
      }
    }
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  #content {
    .assets-hover-menu {
      left: 16.5%;
    }

    &.active {
      .assets-hover-menu {
        left: 9.5%;
      }
    }
  }
  #main-container.split-view-active {
    .faceMatchCards-Container {
      padding: 0% 2% !important;

      .faceMatchCard-deck {
        max-width: 50% !important;
        flex: 0 0 50% !important;
      }
    }

    .thingType-card-deck, .details-card-deck {
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  #content.active {
    #main-container.split-view-active {
      .content-wrapper {
        margin-left: 18% !important;
        -webkit-box-flex: 0;
        flex: 0 0 calc(100% - 18%);
        max-width: calc(100% - 18%) !important;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  #content.active {
    #main-container.split-view-active {
      .content-wrapper {
        margin-left: 13% !important;
        flex: 0 0 calc(100% - 13%) !important;
        max-width: calc(100% - 13%) !important;
      }
    }
  }
}


@media (min-width: 1540px) and (max-width: 1699px) {
  #main-container.split-view-active {
    .services-content-wrapper {
      .asset-card-deck {
        max-width: 50% !important;
        flex: 0 0 50% !important;
      }
    }
  }
}

@media (max-width: 1500px) {
  #main-container.split-view-active {
    .header {
      height: 80px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  .p-datatable.fixedHeight {
    height: 76vh;
    overflow: auto;
  }
}

@media (max-width: 769px) {
  .p-datatable.fixedHeight {
    height: 75vh;
  }
  //.layout-wrapper .layout-main .layout-main-content {
  //  flex: auto;
  //}
  .layout-wrapper {
    // height: 100vh;
    // height: 100svh;
    // overflow-y: auto;
  }
}

// Prime route map journey modal

.leaflet-circle-marker-txt {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;

}

// override for prime fonts for Icon
i:not([class~=pi]) {
  font-size: 18px;
  font-weight: normal;
  font-family : Material-Design-Iconic-Font!important;
}

@media screen and (max-width: 768px) {
  html {
    overflow-y: auto;
    // height: 100%;
  }
}

//------------------------------PRIME-NG RIGHT SIDE BAR STYLES---------------------------------//
.info-prime-sidebar {
  .p-calendar {
    .p-datepicker {
      top: 3rem !important;
      left: auto !important;
    }
  }

  .p-sidebar {
    width: max-content;

    .p-sidebar-header {
      color: #222;
      border-bottom: 1px solid #ced4da;
      justify-content: space-between;
    }

    .p-sidebar-content {
      padding: 0px;
      height: 100%;

      .p-menuitem-link {
        min-width: 175px;
        display: flex;
        justify-content: center;
      }

      .p-tabmenu-nav-btn {
        background: #ffffff;
        color: var(--primary-color);
        width: 2.5rem;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        border-radius: 0;
      }
    }
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .info-prime-sidebar {
    .p-sidebar {
      width: 100%;
      .p-sidebar-header {
        justify-content: space-between;
      }

      .p-tabmenuitem {
        flex-grow: 1;
      }
    }
  }
}

.outsideButton .p-button-icon-only {
  width: 2rem !important;
}

.p-inputnumber-button-group > :first-child{
  padding: 0 !important;
  height: 0.5rem;
  border-radius: 0px 3px 0px 0px;
}
.p-inputnumber-button-group > :nth-child(2){
  padding: 0 !important;
  height: 0.5rem;
  border-radius: 0px 0px 3px 0px;
}

.p-splitbutton{
  .p-button{
    border-radius:3px 0px 0px 3px;
  }
  .p-button-icon-only {
    border-radius:0px 3px 3px 0px;
  }

}
